.our-mission {
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  color: $color-brown-light;

  @include bp-medium {
    background-image: none !important;
    background-color: $color-dark;
  }

  .page--title__small {
    margin-bottom: 0;
  }

  &__content {
    @extend .serif;
    @extend .h2;
    position: relative;
    padding-top: $space * 3;
    padding-bottom: $space * 3;
    justify-content: center;
    align-items: center;
    text-align: center;
    z-index: 1;

    @include bp-medium {
      display: flex;
      width: 100%;
      height: 100%;
      padding-top: $space * 10;
      padding-bottom: $space * 5;
    }

    @include bp-large {
      padding-bottom: $space * 11.25;

      &, p {
        font-size: 34px;
        line-height: 50px;
        letter-spacing: -0.56px;
      }
    }
  }

  a {
    margin-top: $space * 2;
    letter-spacing: 1.74px;

    @include bp-medium {
      margin-top: $space * 4;
    }

    @include bp-large {
      margin-top: $space * 5.5;
    }

    &, &:hover {
      color: white;
    }
  }
}

.our-mission__bg {
  display: none;

  @include bp-medium {
    position: absolute;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    z-index: 0;
  }
}

.our-mission__top {
  position: relative;
  padding-top: $space * 2;
  padding-bottom: $space;
  z-index: 1;

  @include bp-medium {
    padding-top: $space * 4;
    padding-bottom: $space * 3;
  }

  @include bp-large {
    padding-top: $space * 6;
    padding-bottom: $space * 5;
  }

  @include bp-xlarge {
    padding-top: $space * 8.5;
    padding-bottom: $space * 10;
  }
}

.our-mission__top-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.our-mission__top-item {
  display: flex;
  flex-direction: column;

  &:nth-child(even) {
    flex-direction: column-reverse;
    margin-left: auto;
    padding-bottom: $space;

    @include bp-medium {
      padding-bottom: $space * 2.5;
    }
  }
}

.our-mission__top-item-content {
  display: inline-block;
  color: $color-smoke;

  @include bp-medium {
    font-size: 18px;
  }

  p {
    margin: $space 0;
    line-height: 1.4;

    @include bp-medium {
      margin: $space * 1.5 0;
    }
  }
}
