/* ==========================================================================
   #MAIN NAV
   ========================================================================== */

.c-main-nav {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding-left: $space;
  padding-right: $space;
  text-align: right;
  list-style: none;

  @media (min-width: 880px) {
    flex-direction: row;
    text-align: left;
  }

  &--wrapper {
    position: absolute;
    left: -9999px;
    top: 100%;
    width: 100%;
    height: calc(100vh - 50px);
    background-color: white;
    transform: translateX(100%);
    transition: transform 0.3s ease-in-out;

    &.is-active {
      left: 0;
      transform: translateX(0);

      &.hide {
        transform: translateX(100%);
      }
    }

    @media (min-width: 880px) {
      position: static;
      width: auto;
      height: auto;
      transform: none;
    }
  }

  &__toggle {
    @media (min-width: 880px) {
      display: none;
    }
  }
}

.c-main-nav__item {
  margin: 0;

  &:last-of-type {
    .c-main-nav__link {
      margin-right: 0;
    }
  }
}

.c-main-nav__link {
  @extend .sans-serif-special;
  position: relative;
  display: block;
  padding: $space-small $space-small * 1.5;
  font-size: 30px;
  text-transform: uppercase;
  text-decoration: none;
  color: $color-text;

  @media (min-width: 880px) {
    margin: 0 $space;
    padding: $space-small 0;
  }

  @include bp-large {
    font-size: 19px;
  }

  &:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 9px;
    display: block;
    width: 100%;
    height: 2px;
    background-color: $color-brown;
    transform: translateY(2px);
    opacity: 0;
    transition: $transition;
  }

  &.is-active,
  &:hover {
    color: $color-text;

    &:after {
      opacity: 1;
      transform: translateY(0);
    }
  }

  &.has-children {
    @media (min-width: 880px) {
      padding-right: $space-small * 1.5;
      background: url('../assets/images/icons/arrow-down.svg') no-repeat right center;
    }
  }
}

.current_page_parent .c-main-nav__link::after,
.current-menu-item .c-main-nav__link::after {
  opacity: 1;
  transform: none;
}

.c-main-nav__dropdown {
  display: flex;
  margin: 0;

  .mobile & {
    display: block;
  }
}

.c-main-nav__dropdown--wrapper {
  display: none;
  width: 100%;

  &.mobile {
    display: block;
    flex-direction: column;
    min-width: 100%;
    margin: 0;

    @media (min-width: 880px) {
      display: none;
    }
  }

  @media (min-width: 880px) {
    position: absolute;
    top: 100%;
    left: 0;
    display: block;
    transform: translateY(-100%);
    z-index: -2;
    transition: $transition;
  }

  &.is-active {
    transform: translateY(0);
  }
}

.c-main-nav__subitem {
  flex: 1;
  margin: 0;
  list-style: none;
}

.c-main-nav__sublink {
  @extend .serif;
  display: block;
  padding: $space-small 0;
  font-size: 20px;

  @media (min-width: 880px) {
    padding: 27px $space-small;
    font-size: 22px;
    color: white;
    text-align: center;
    text-decoration: none;
  }

  @include bp-large {
    padding: 38px $space-small;
    font-size: 28px;
  }

  .mobile & {
    @extend .sans-serif-special;
    padding-right: $space-small * 1.5;
  }

  &:hover {
    color: white;

    .c-main-nav__sublink-text:after {
      opacity: 1;
      transform: translate(-50%, 0);
    }
  }

  &-text {
    position: relative;

    &:after {
      content: '';
      position: absolute;
      left: 50%;
      bottom: -3px;
      display: block;
      width: 100%;
      height: 1px;
      background-color: white;
      opacity: 0;
      transform: translate(-50%, 2px);
      transition: $transition;
    }
  }
}

.menu-item-sustainable {
  .c-main-nav__sublink {
    @media (min-width: 880px) {
      background: $color-sustainable url('../assets/images/sustainable-bg.jpg');
      background-size: cover;
    }
  }
}

.menu-item-organic {
  .c-main-nav__sublink {
    @media (min-width: 880px) {
      background: $color-organic url('../assets/images/organic-bg.jpg');
      background-size: cover;
    }
  }
}
