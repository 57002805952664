/* ==========================================================================
   #POST
   Includes styles for default WP images classes
   ========================================================================== */

.c-post {
  @include clearfix();
  padding-top: $space * 2.25;
  background-color: $color-smoke;

  @include bp-medium {
    padding-top: $space * 4.25;
  }

  @include bp-large {
    padding-top: $space * 5.25;
  }

  &__categories,
  &__content,
  &__title {
    max-width: 700px;
    margin-left: auto;
    margin-right: auto;
  }

  &__title {
    margin-bottom: 0;
  }

  &__thumb {
    width: 100%;
    max-width: 1000px;
    margin-top: $space * 2;
    margin-left: auto;
    margin-right: auto;

    @include bp-medium {
      margin-top: $space * 3;
    }

    @include bp-large {
      margin-top: $space * 3.5;
    }

    &-img {
      width: 100%;
    }
  }

  &__content {
    margin-top: $space * 1.5;

    @include bp-medium {
      margin-top: $space * 2.5;
    }

    @include bp-large {
      margin-top: $space * 3;
    }
  }

  &__categories {
    display: flex;
    flex-wrap: wrap;
    color: rgba($color-text, 0.38);
  }

  &__category {
    display: inline-block;
    color: rgba($color-text, 0.38);

    &-sep {
      display: inline-block;
    }
  }
}

.alignnone {
  margin: $space $space $space 0;
}

.aligncenter {
  margin: $space auto;
}

.alignright {
  margin: $space 0 $space $space;
  float: right;
}

.alignleft {
  margin: $space $space $space 0;
  float: left;
}

.wp-caption {
  text-align: center;
}

.wp-caption-text {
  margin-top: $space-small;
  font-style: italic;
}

.addtoany_share_save_container.addtoany_content {
  margin-top: $space;
  margin-bottom: 0;
  padding-top: $space * 1.5;
  padding-bottom: $space * 3;
  border-top: 1px solid $color-very-light-grey;

  @include bp-medium {
    margin-top: $space * 2.5;
    padding-top: $space * 2.5;
    padding-bottom: $space * 5;
  }

  .addtoany_list {
    display: block;

    &:before {
      @extend .sans-serif-special;
      content: 'Share this:';
      display: inline-block;
      vertical-align: middle;
      color: $color-brown;
      font-size: 20px;
    }
  }

  a {
    position: relative;
    display: inline-block;
    width: 34px;
    height: 34px;
    margin: 9px;
    border-radius: 50%;

    @include bp-medium {
      width: 44px;
      height: 44px;
    }

    &:after {
      content: '';
      position: absolute;
      left: 50%;
      top: 50%;
      display: block;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      box-shadow: 0 0 0 0 rgba(200,170,129,1);
      transform: translate(-50%, -50%);
      transition: $transition;

      @include bp-medium {
        width: 40px;
        height: 40px;
      }
    }

    &:hover:after {
      box-shadow: 0 0 0 4px rgba(200,170,129,1);
    }
  }

  .a2a_svg {
    display: none;
  }

  .a2a_button_twitter {
    background: url('../assets/images/icons/twitter.svg') no-repeat center center;
    background-size: contain;
  }

  .a2a_button_facebook {
    background: url('../assets/images/icons/facebook.svg') no-repeat center center;
    background-size: contain;
  }

  .a2a_button_email {
    background: url('../assets/images/icons/email.svg') no-repeat center center;
    background-size: contain;
  }
}

.single-post {
  .news-stories--item__first {
    flex: 0 0 50%;
    min-width: 300px;
  }
}
