/* ==========================================================================
   #FOOTER
   ========================================================================== */

.c-footer {
  padding-top: $space * 2.5;
  padding-bottom: $space * 2;
  overflow: hidden;

  @include bp-medium {
    padding-top: $space * 3;
  }

  &--below,
  .sbi {
    position: relative;
    z-index: 1;
  }

  &--below {
    max-width: 1180px;

    > .o-layout:not(.c-footer--lower) {
      display: flex;
      flex-wrap: wrap;
    }
  }

  &--insta {
    position: relative;
    margin-bottom: $space * 2;
    padding-left: 0;
    padding-right: 0;

    @include bp-medium {
      margin-bottom: $space * 4.5;
    }

    &__feed {
      position: relative;

      &:after {
        content: '';
        position: absolute;
        left: -100%;
        right: -100%;
        top: 50%;
        display: block;
        height: 2000px;
        background-color: $color-brown-very-light;
        z-index: 0;
      }

      .sbi_item {
        @include bp-xlarge {
          height: 280px;
        }
      }
    }

    &__wrapper {
      font-size: 18px;
      text-align: center;
    }

    &__title {
      @extend .serif;
      margin-bottom: 0;
      font-weight: $font-bold;
    }

    &__follow {
      margin-bottom: $space * 2.5;

      a {
        letter-spacing: initial;
        text-transform: none;

        &:after {
          display: none;
        }
      }
    }
  }

  &--lower {
    @extend .sans-serif-special;
    justify-content: center;
    margin-top: $space * 2;
    padding-top: $space * 1.5;
    border-top: 1px solid rgba(#979797, 0.15);
    font-size: 15px;
    text-align: center;
    color: rgba($color-text, 0.5);

    @include bp-medium {
      text-align: left;
    }

    p {
      margin: 0;
    }

    a {
      display: block;
      margin: 0;
      padding: 0;
      text-transform: none;
      text-align: center;
      font-size: 15px;
      color: rgba($color-text, 0.5);

      @include bp-medium {
        display: inline-block;
      }

      &:after {
        display: none;
      }

      &:hover {
        text-decoration: none;
        color: #181817;
      }
    }

    > a {
      letter-spacing: initial;

      &:hover {
        color: #706D65;
      }
    }
  }

  &--cert {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-top: $space;
    margin-bottom: $space;
    margin-left: auto;
    margin-right: auto;

    @include bp-medium {
      margin-top: 0;
      margin-bottom: 0;
      margin-left: $space * 4;
    }

    img {
      margin: 0 $space-small;
    }
  }

  &--nav {
    margin: 0;
    list-style: none;

    &__item {
      margin-bottom: $space-small;
    }

    &__subitem {
      margin-bottom: $space-small;
    }

    a {
      @extend .serif;
      font-weight: bold;
      color: $color-text;

      &:hover {
        text-decoration: none;
        color: $color-brown;
      }
    }

    &__dropdown {
      margin-left: 0;
      list-style: none;

      a {
        font-weight: normal;
      }
    }

    &__1 {
      .c-footer--nav__item:last-of-type {
        margin-top: $space * 2.25;

        @include bp-medium {
          margin-top: $space * 1.9;
        }
      }
    }
  }

  &--contact {
    @media (max-width: $bp-medium - 1) {
      flex: 0 0 100%;
      margin-top: $space * 2;
      text-align: center;
    }

    p {
      @extend .serif;
      margin-bottom: $space-small;
      line-height: 1.6  ;
      font-size: 14px;

      a {
        margin: 0;
        padding: 0;
        font: inherit;
        font-size: 14px;
        color: inherit;
        text-decoration: none;

        &:after {
          display: none;
        }
      }
    }

    h4 {
      font-size: 16px;
    }
  }

  &--social {
    display: flex;
    max-width: 146px;
    margin-left: auto;
    margin-right: auto;
    margin-top: $space * 2;

    @include bp-medium {
      margin-left: 0;
    }

    svg path {
      transition: $transition;
    }

    svg path {
      fill: $color-brown-light;
    }

    a:hover svg path {
      fill: $color-dark;
    }
  }

  &--newsletter {
    h3 {
      margin-bottom: $space-small / 2;
      font-size: 20px;
      font-weight: $font-bold;
    }

    p {
      @extend .serif;
      font-size: 12px;
    }

    @media (max-width: $bp-medium - 1) {
      flex: 0 0 100%;
      margin-bottom: $space * 1.5;
      text-align: center;
    }

    @include bp-large {
      min-width: 480px;
    }

    ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
      font-family: $font-sans-serif-special;
      color: black;
    }
    ::-moz-placeholder { /* Firefox 19+ */
      font-family: $font-sans-serif-special;
      color: black;
    }
    :-ms-input-placeholder { /* IE 10+ */
      font-family: $font-sans-serif-special;
      color: black;
    }
    :-moz-placeholder { /* Firefox 18- */
      font-family: $font-sans-serif-special;
      color: black;
    }

    .gfield_label {
      position: absolute;
      left: -9999px;
      visibility: hidden;
    }

    .gform_wrapper {
      max-width: 350px;
      margin-top: $space;

      @include bp-medium {
        margin-top: $space * 1.5;
      }
    }

    .gform_wrapper .top_label input.medium, .gform_wrapper .top_label select.medium {
      width: 100%;
      padding-right: 70px;
      border: none;
      border-bottom: 1px solid rgba($color-text, 0.29);
      outline: none;
      background: none;
    }

    .gform_wrapper ul.gform_fields li.gfield {
      padding-right: 0;
    }

    form {
      position: relative;
    }

    .gform_wrapper .gform_footer {
      position: absolute;
      top: 0;
      right: 0;
      width: auto;
      height: 30px;
      margin: 0;
      padding: 0;
    }

    .gform_wrapper .gform_footer input.button, .gform_wrapper .gform_footer input[type=submit], .gform_wrapper .gform_page_footer input.button, .gform_wrapper .gform_page_footer input[type=submit] {
      @extend .sans-serif-special;
      height: 100%;
      margin-right: 0;
      background: none;
      border: none;
      outline: none;
      color: $color-brown;
      cursor: pointer;
      transition: $transition;

      &:hover {
        opacity: 0.5;
      }
    }

    .gform_wrapper div.validation_error {
      position: absolute;
      top: 100%;
      margin: 0;
      padding: 0;
      font-weight: $font-normal;
      font-size: 13px;
      color: $color-organic;
      border: none;
    }

    .gform_wrapper.gform_validation_error .gform_body ul li.gfield.gfield_error:not(.gf_left_half):not(.gf_right_half) {
      max-width: 100% !important;
      margin: 0;
    }

    .gform_wrapper li.gfield.gfield_error, .gform_wrapper li.gfield.gfield_error.gfield_contains_required.gfield_creditcard_warning {
      width: 100% !important;
      margin: 0 !important;
      padding: 0;
      background: none;
      border: none;
    }

    .gform_wrapper li.gfield_error input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]), .gform_wrapper li.gfield_error textarea {
      width: 100%;
      padding-right: 70px;
      border: none;
      border-bottom: 1px solid rgba($color-text, 0.29);
    }

    .gform_wrapper .field_description_below .gfield_description {
      padding: 0;
      padding-bottom: $space-small;
      color: $color-organic;
    }
  }

  #sb_instagram #sbi_images {
    display: flex;
  }

  #sb_instagram .sbi_photo {
    height: 0 !important;
    padding-bottom: 100%;
  }
}
