/* ==========================================================================
   #BLOCKQUOTES
   ========================================================================== */


blockquote {
  @extend .sans-serif-special;
  quotes: '“' '”' '‘' '’';
  max-width: 600px;
  margin-top: $space-small;
  margin-bottom: $space-small;
  margin-left: auto;
  margin-right: auto;
  font-style: italic;
  font-size: 19px;

  @include bp-medium {
    margin-bottom: $space;
    font-size: 28px;
  }

  p {
    line-height: initial;
  }

  p:first-child {
    &:before {
      content: open-quote;
    }
  }

  p:last-child {
    &:after {
      content: close-quote;
    }
  }
}

p + blockquote {
  margin-top: $space-small;

  @include bp-medium {
    margin-top: $space;
  }
}
