/* ==========================================================================
   #BUTTONS
   ========================================================================== */

.c-btn {
  @extend .sans-serif-special;
  display: inline-block;
  margin: 0;
  padding: $space-small $space;
  vertical-align: middle;
  border: 1px solid $color-brown-light;
  font-size: 16px;
  color: $color-brown-light;
  letter-spacing: 1.57px;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  cursor: pointer;
  transition: $transition;

  @include bp-medium {
    padding: $space-small * 1.5 $space;
  }

  @include bp-large {
    font-size: 18px;
  }

  &:hover {
    color: white;
    background-color: $color-brown-light;
  }

  &:after {
    display: none;
  }

  &--full {
    color: white;
    background-color: $color-brown-light;

    &:hover {
      background-color: #B09063;
    }
  }
}

.c-btn--large {
  min-width: 200px;
  font-weight: normal;
}
