.image-title {
  padding-top: $space * 2;
  padding-bottom: $space * 2;

  @include bp-medium {
    padding-top: 115px;
    padding-bottom: 115px;
  }

  @include bp-large {
    padding-top: 175px;
    padding-bottom: 175px;
  }

  h2 {
    margin-bottom: $space-small * 2;

    @include bp-medium {
      margin-bottom: $space * 1.75;
    }
  }

  a {
    @extend .c-btn;
    @extend .c-btn--large;

    width: 100%;
    margin-top: $space;

    @include bp-medium {
      width: auto;
      margin-top: $space * 2;
    }
  }

  &__item {
    flex: 0 0 35%;
    padding: 0 $space;
    text-align: center;

    @include bp-medium {
      padding: 0;
      text-align: left;
    }

    &-img {
      display: none;

      @include bp-medium {
        flex: 0 0 55%;
        display: block;
      }
    }
  }
}
