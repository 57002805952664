$customBp: 600px;

.split-section {
  display: flex;
  
  @media (max-width: $customBp) {
    flex-direction: column;
  }
}

.split-section__item {
  position: relative;
  flex: 0 0 50%;
  width: 50%;
  background-color: black;
  
  @media (max-width: $customBp) {
    flex: 0 0 100%;
    width: 100%;
    margin-bottom: -1px;
  }
  
  &:hover {
    .split-section__item-img-hover {
      opacity: 1;
    }

    .split-section__item-img-hover img {
      transform: scale(1.01);
    }

    .split-section__content {
      opacity: 1;
    }
    
    .split-section__link,
    .split-section__title { 
      transform: translateY(0) !important;
    }

    .split-section__item-img.animate img {
      opacity: 0.75;
      transform: scale(1.1);
    }
  }
}

.split-section__item-img {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 0;

  img {
    width: 100%;
    height: auto;
    transition: all 1.5s ease;
  }
}

.split-section__item-img-hover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 1;
  transition: $transition;
  overflow: hidden;
  
  img {
    width: 100%;
    height: auto;
    transform: scale(1.1);
    transform-origin: 50% 50%;
    transition: all 0.4s ease-in-out;
  }
}

.split-section__item-content {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  text-align: center;
  transform: translateY(-50%);
  z-index: 2;

  @media (max-width: $customBp) {
    padding: $space-small $space;
  }

  &,
  .link,
  .link:hover {
    color: white;
  }
}

.split-section__title {
  @media (max-width: $customBp) {
    margin-bottom: $space;
    font-size: 30px;
  }

  @include bp-large {
    margin-bottom: $space-small;
    font-size: 50px;
  }
}

.split-section__link,
.split-section__title { 
  transition: $transition;

  @media (max-width: $customBp) {
    transform: translateY(0) !important;
  }
}

.split-section__content {
  max-width: 450px;
  margin-left: auto;
  margin-right: auto;
  opacity: 0;
  transition: $transition;
  
  @media (max-width: $customBp) {
    opacity: 1;
  }
}

.split-section__link .link {
  @media (max-width: $customBp) {
    font-size: 20px;
  }
}