.c-hero {
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center bottom;

  .home & {
    min-height: calc(60vh - 50px);

    @media (min-height: 440px) and (max-width: $bp-medium - 1) {
      height: calc(50vh - 50px);
      padding-top: 0;
    }
  }

  @include bp-medium {
    min-height: 0;
    padding-top: 0;
  }

  &--content {
    position: relative;
    top: 50%;
    text-align: center;
    transform: translateY(-50%);

    @include bp-medium {
      top: 0;
      text-align: left;
      transform: none;
    }
  }

  &--link {
    @extend .sans-serif-special;
    position: relative;
    padding: 5px;
    padding-left: 32px;
    font-size: 20px;
    color: $color-text;

    @include bp-medium {
      font-size: 22px;
    }

    span {
      text-shadow: rgba($color-brown-very-light, 0.5) 1px 0 1px;
    }

    span, svg {
      display: inline-block;
      vertical-align: middle;
    }

    svg {
      position: absolute;
      left: 0;
      top: 50%;
      overflow: visible;
      transform: translateY(-50%);

      .circle {
        transform-origin: center center;
        transition: $transition;
      }
    }

    &:hover {
      svg .circle {
        transform: scale(1.2);
      }
    }
  }

  &--scroll {
    @extend .sans-serif-special;
    display: none;

    @include bp-medium {
      position: absolute;
      bottom: 0;
      left: 50%;
      display: block;
      width: 30px;
      height: 65px;
      margin-left: -15px;
      color: white;
      font-size: 16px;
      text-transform: uppercase;
      background: url('../assets/images/icons/scroll-down.svg') no-repeat center center;
      background-size: contain;
    }
  }

  &--img {
    width: 100%;
  }

  &--text {
    max-width: 500px;
  }

  &--regular__content {
    @include bp-medium {
      position: absolute;
      left: 0;
      top: 100%;
      width: 100%;
      transform: translateY(-50%);
    }
  }

  &--regular__text {
    @extend .serif;
    position: relative;
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
    padding: $space * 2.5 $space * 1.5;
    background-color: $color-green;
    font-size: 18px;
    line-height: 26px;
    color: $color-smoke;
    text-align: center;

    @include bp-medium {
      padding: $space * 3 $space * 4.5;
      font-size: 26px;
      line-height: 44px;
    }

    @include bp-large {
      padding: $space * 3.5 $space * 4.5;
      font-size: 27px;
      line-height: 47px;
    }

    &:after {
      content: '';
      position: absolute;
      left: 8px;
      right: 8px;
      top: 8px;
      bottom: 8px;
      display: block;
      border: 1px solid lighten($color-green, 15%);
      z-index: 1;
    }

    p {
      &:first-of-type {
        margin-top: 0;
      }

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  &--process {
    @media (min-height: 440px) {
      position: relative;
    }

    @include bp-medium {
      position: absolute;
      top: 50%;
      left: 0;
      width: 100%;
      transform: translateY(-60%);
    }

    @media (max-height: 760px) and (min-width: $bp-medium) {
      position: relative;
      padding-top: $space * 2;
      transform: none;
    }

    &__wrapper {
      padding-bottom: 100px;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;

      @include bp-medium {
        padding-bottom: 0;
        transform: none;
      }

      .c-hero--img {
        display: none;

        @include bp-medium {
          display: inline-block;
        }
      }
    }

    &__content {
      max-width: 935px;
      margin-left: auto;
      margin-right: auto;
      text-align: center;
      color: $color-smoke;
    }

    &__text {
      max-width: 575px;
      margin-top: $space * 1.5;
      margin-left: auto;
      margin-right: auto;
      padding-left: $space-small * 1.5;
      padding-right: $space-small * 1.5;

      @include bp-medium {
        margin-top: $space * 3;
        font-size: 19px;
      }
    }

    &__next {
      @extend .sans-serif-special;
      position: absolute;
      left: 50%;
      bottom: 0;
      transform: translateX(-50%);

      @media (max-height: 760px) and (min-width: $bp-medium) {
        position: relative;
        display: inline-block;
        margin-top: $space * 2;
      }

      &:after {
        content: '';
        display: block;
        width: 1px;
        height: $space * 3;
        margin-top: $space-small;
        margin-left: auto;
        margin-right: auto;
        background-color: $color-smoke;
      }
    }
  }
}

.c-hero--process {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  color: white;
  text-align: center;

  h1 {
    @include bp-large {
      font-size: 54px;
    }
  }
}
