button {
  display: inline-block;
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  color: inherit;
}

.large-play {
  position: relative;
  width: 79px;
  height: 79px;
  background: url('../assets/images/icons/play-triangle.svg') no-repeat center center;
  background-size: 10px 15px;
  border-radius: 50%;
  cursor: pointer;
  transition: $transition;

  @include bp-medium {
    background-size: 20px 25px;
  }

  &:after {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    display: block;
    width: 100%;
    height: 100%;
    border: 1px solid white;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    transition: $transition;
  }

  &:hover {
    &:after {
      width: 100px;
      height: 100px;
      box-shadow: 0px 0px 0px 2px white;
    }
  }

  &--xl {
    width: 55px;
    height: 55px;

    @include bp-medium {
      width: 75px;
      height: 75px;

      &:hover {
        &:after {
          width: 95px;
          height: 95px;
        }
      }
    }

    @include bp-large {
      width: 95px;
      height: 95px;

      &:hover {
        &:after {
          width: 110px;
          height: 110px;
        }
      }
    }
  }
}

.custom-arrow {
  display: none;

  @include bp-medium {
    display: block;
    width: 60px;
    height: 60px;
    background: none;
    transition: $transition;
  }

  svg path {
    transition: $transition;
  }

  &:hover {
    svg path {
      fill: $color-brown-light;
    }
  }
}
