/* ==========================================================================
   #HEADER
   ========================================================================== */

.c-header {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 50px;
  padding-left: $space;
  padding-right: $space-small / 2;
  transform: translateZ(0);
  z-index: 99;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    width: 100%;
    height: 100%;
    background-color: white;
    z-index: -1;
  }

  @include bp-medium {
    position: absolute;
    height: $header-height;
    padding-right: $space;
  }

  @media (min-width: 1110px) {
    padding-left: $space * 3.5;
    padding-right : $space * 3.5;
  }

  &--logo {
    display: block;

    @include bp-medium {
      padding: 0 $space-small;
    }

    img {
      @media (max-width: $bp-medium - 1) {
        height: 32px;
      }
    }

    &-white {
      display: none;
    }
  }

  &--subnav-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    display: none;
    width: 100%;
    height: 100%;
    background-color: rgba($color-smoke, 0.8454);
    z-index: 98;
  }
}
