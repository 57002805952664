$color-history: #B09063;
.our-history {
  padding-top: 50px;
  padding-bottom: 40px;
  background-color: $color-brown-very-light;

  @include bp-medium {
    padding-top: 85px;
    padding-bottom: 100x;
  }

  @include bp-large {
    padding-top: 115px;
    padding-bottom: 115px;
  }

  h2 {
    text-align: center;
  }

  &--years {
    position: relative;
    display: flex;
    justify-content: center;
    width: 560px;
    margin-left: auto;
    margin-right: auto;
    margin-top: $space;
    z-index: 1;

    @include bp-medium {
      width: auto;
      margin-top: $space * 3;
    }

    &__container {
      width: 560px;
      margin: 0 auto;

      @include bp-medium {
        width: auto;
      }
    }

    &__item {
      flex: 0 0 80px;
      text-align: center;

      @include bp-medium {
        flex: 1;
      }
    }

    &__wrapper {
      position: relative;
      max-width: 930px;
      margin-left: $space-small * -1.5;
      margin-right: $space-small * -1.5;
      overflow: auto;

      @include bp-medium {
        margin-left: auto;
        margin-right: auto;
      }
    }

    &__line {
      position: absolute;
      left: 0;
      bottom: 6.5px;
      height: 1px;
      width: 100%;
      background-color: #D8D8D8;
      overflow: hidden;
      z-index: 0;

      @include bp-medium {
        left: 50%;
        transform: translateX(-50%);
      }

      &:before,
      &:after {
        content: '';
        position: absolute;
        top: 0;
        display: block;
        height: 1px;
        width: 90px;
        z-index: 2;
      }

      &:before {
        left: 0;
        background: -moz-linear-gradient(left, $color-brown-very-light 0%, rgba(0,0,0,0) 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(left, $color-brown-very-light 0%,rgba(0,0,0,0) 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to right, $color-brown-very-light 0%,rgba(0,0,0,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$color-brown-very-light', endColorstr='rgba(0,0,0,0)',GradientType=1 ); /* IE6-9 */
      }

      &:after {
        right: 0;
        background: -moz-linear-gradient(left, rgba(0,0,0,0) 0%, $color-brown-very-light 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(left, rgba(0,0,0,0) 0%, $color-brown-very-light 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to right, rgba(0,0,0,0) 0%, $color-brown-very-light 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='transparent', endColorstr='$color-brown-very-light',GradientType=1 ); /* IE6-9 */
      }

      &-current {
        position: absolute;
        right: 0;
        left: 0;
        height: 1px;
        z-index: 1;

        .status {
          position: absolute;
          left: 0;
          top: 0;
          height: 1px;
          width: 16.666666%;
          background-color: $color-history;
          transition: $transition;
        }
      }
    }
  }

  &--year {
    @extend .serif;
    font-size: 15px;
    color: rgba($color-text, 0.37);

    span {
      display: block;
      transition: $transition;
    }

    .circle {
      margin-top: $space;
      margin-left: auto;
      margin-right: auto;
      width: 12px;
      height: 12px;
      border: 1px solid $color-history;
      border-radius: 50%;
      background-color: $color-smoke;
    }

    &.active,
    &:hover {
      color: $color-history;

      .circle {
        background-color: $color-history;
      }
    }
  }

  &--slider {
    width: 100%;
    max-width: 1260px;
    margin-top: $space * 1.5;
    margin-left: auto;
    margin-right: auto;

    @include bp-medium {
      margin-top: 90px;
      padding: 0 100px;
    }

    @include bp-large {
      padding: 0 140px;
    }

    img {
      height: auto;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: $space;
    }

    .slick-list {
      padding: 0 !important;
    }

    .c-slider--prev,
    .c-slider--next {
      width: 58px;
      padding: 0;
      background: none;

      @media (max-width: $bp-medium - 1) {
        display: none !important;
      }

      @include bp-medium {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
      }

      svg path {
        fill: $color-text;
        transition: $transition;
      }

      &:hover {
        svg path {
          fill: $color-brown;
        }
      }
    }

    .c-slider--prev {
      left: 0;
    }

    .c-slider--next {
      left: auto;
      right: 0;
    }
  }

  &--slide {
    padding: 0 $space;
    text-align: center;

    @include bp-medium {
      text-align: left;
    }

    &__text {
      @include bp-medium {
        padding-left: $space * 3;
      }

      h3 {
        margin-bottom: $space;
        line-height: 1.5;

        @include bp-medium {
          font-size: 23px;
        }
      }

      p {
        font-size: 14px;

        @include bp-medium {
          font-size: 15px;
        }
      }
    }
  }
}
