/* ==========================================================================
   #LISTS
   ========================================================================== */
.list {
  margin: 0;
  padding: 0;
  list-style: none;
}

li {
  margin-bottom: $space-small / 2;

  > ul,
  > ol {
    margin-bottom: 0;
    margin-left: $space;
    margin-top: $space-small;
  }
}
