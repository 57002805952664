.influence {
  .page--title-lined {
    border-bottom: none;

    h2 {
      max-width: 100%;
      margin-bottom: $space-small;
    }
  }

  &--content {
    max-width: 585px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }

  &--slider {
    width: 100%;
    margin-top: $space;

    @include bp-medium {
      margin-top: $space * 4.5;
    }

    .c-slider--prev,
    .c-slider--next {
      @extend .custom-arrow;

      @include bp-medium {
        display: none;
      }

      @include bp-large {
        display: block;
      }
    }
  }

  &--item {
    max-width: 100%;
    padding-left: 7px;
    padding-right: 7px;

    @include bp-large {
      padding-left: $space * 3;
      padding-right: $space * 3;
    }

    h4, .influence--preview {
      opacity: 0;
      transition: $transition;
    }

    h4 {
      margin-top: $space;

      @media (max-width: $bp-medium - 1) {
        font-size: 17px;
      }

      @include bp-medium {
        margin-top: $space * 1.75;
      }
    }

    &__placeholder {
      position: relative;
      display: inline-block;
      opacity: 0.3;
      transition: $transition;

      img {
        position: relative;
        transition: $transition;
        z-index: 1;

        &.fade-out {
          opacity: 0 !important;
        }

        &.hide {
          z-index: -1;
        }
      }

      .large-play {
        position: absolute;
        top: 50%;
        left: 50%;
        display: block;
        transform: translate(-50%, -50%);
        transition: $transition;
        z-index: 2;

        &.fade-out {
          opacity: 0 !important;
        }

        &.hide {
          z-index: -1;
        }
      }

      &--video {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 0;

        iframe {
          width: 100%;
          height: 100%;
        }
      }
    }

    &__wrapper {
      position: relative;
    }
  }

  &--preview {
    width: 100%;
    max-width: 535px;
    padding-bottom: 5px;

    .read-more {
      display: none;
    }

    &__link {
      display: block;
      margin-top: $space;

      @include bp-large {
        position: absolute;
        right: 0;
        bottom: $space-small;
        display: inline-block;
        margin-top: 0;
      }
    }

    &__text {
      @media (max-width: $bp-medium - 1) {
        font-size: 13px;
      }
    }
  }
}

.slick-slide {
  pointer-events: none;
  outline: none;

  &.slick-current {
    pointer-events: initial;

    h4, .influence--preview, .influence--item__placeholder {
      opacity: 1;
    }
  }
}

.c-slider--next,
.c-slider--prev {
  display: none;

  @include bp-large {
    position: absolute;
    top: 41.5%;
    left: 50%;
    width: 30px;
    height: 30px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    transform: translate(-50%, -50%);
    transition: $transition;
    z-index: 10;
  }

  &.slick-disabled {
    opacity: 0;
    pointer-events: none;
  }
}

.c-slider--next {
  background-image: url('../assets/images/icons/arrow-right.svg');
}

.c-slider--prev {
  background-image: url('../assets/images/icons/arrow-left.svg');
}

