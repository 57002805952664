.our-process {
  padding-top: $space * 2;
  padding-bottom: $space * 2;

  @include bp-medium {
    padding-top: 100px;
    padding-bottom: $space * 2.5;
  }

  @include bp-large {
    padding-top: 125px;
    padding-bottom: 100px;
  }

  .page--title-lined {
    border-bottom: none;
  }

  &--icon {
    @extend .serif;
    position: relative;
    display: inline-block;
    flex: 0 0 134px;
    margin-top: $space * 2.5;
    margin-right: $space * 2.2;
    text-align: center;
    z-index: 1;

    @include bp-medium {
      display: block;
      margin-right: 0;
    }

    img {
      width: 132px;
      height: 132px;
    }
  }

  &--icon-step {
    margin: 0;
    margin-top: $space;
    text-transform: uppercase;
    font-size: 12px;
    color: $color-grey;
  }

  &--icon-title {
    position: relative;
    margin: 0;
    font-weight: $font-bold;

    @include bp-medium {
      font-size: 22px;
    }

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 10px;
      right: 10px;
      display: block;
      height: 100%;
      background-color: $color-smoke;
      z-index: -1;
    }
  }

  &--it {
    padding-top: $space * 2;
    text-align: center;

    @include bp-large {
      padding-top: 120px;
    }

    &__item {
      flex: 0 0 42%;
      margin-top: $space * 1.75;

      @include bp-medium {
        margin-top: 0;
      }
    }

    &__content {
      max-width: 460px;
    }

    &.odd {
      display: flex;
      flex-direction: column-reverse;

      @include bp-medium {
        flex-direction: row;
      }

      .our-process--it__content {
        margin-left: auto;
      }

      .our-mill__it-content {
        @include bp-medium {
          padding-right: $space;
        }

        @include bp-xlarge {
          padding-right: 0;
        }
      }
    }

    &.even {
      display: flex;
      flex-direction: column-reverse;

      @include bp-medium {
        flex-direction: row-reverse;
      }

      .our-mill__it-content {
        @include bp-medium {
          padding-left: $space;
        }

        @include bp-xlarge {
          padding-left: 0;
        }
      }
    }

    ul {
      margin: 0;
      padding: 0;
      list-style: none;

      li {
        line-height: 26px;
        color: rgba($color-dark, 0.5);
        transition: $transition;

        &:hover {
          color: $color-dark;
        }
      }
    }

    a {
      margin-top: $space;

      &:after {
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }

  &--icons {
    position: relative;
    display: flex;
    width: calc(50% + 890px - 44px);
    z-index: 1;
    margin-left: calc(50% - 66px);
    padding-right: calc(50% - 111px);

    @include bp-medium {
      width: auto;
      margin-top: $space * 3;
      margin-left: 0;
      margin-right: 0;
      padding: 0;
    }

    &__wrapper {
      position: relative;
      max-width: 1050px;
      margin-left: $space-small * -1.5;
      margin-right: $space-small * -1.5;
      padding: 0;
      overflow: auto;

      @include bp-medium {
        margin-left: auto;
        margin-right: auto;
      }
    }

    &__wrapper-ext {
      position: relative;

      &:before,
      &:after {
        content: '';
        position: absolute;
        top: 0;
        display: block;
        width: $space * 2.5;
        height: 100%;
        background: -moz-linear-gradient(left, rgba($color-smoke, 0) 10%, $color-smoke 100%);
        /* FF3.6-15 */
        background: -webkit-linear-gradient(left, rgba($color-smoke, 0) 10%, $color-smoke 100%);
        /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to right, rgba($color-smoke, 0) 10%, $color-smoke 100%);
        /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='rgba(255, 255, 255, 0)', endColorstr='#F3F1E9', GradientType=1);
        /* IE6-9 */
        z-index: 2;

        @include bp-medium {
          opacity: 0;
        }
      }

      &:after {
        right: 0;
      }

      &:before {
        left: 0;
        transform: rotate(180deg);
      }
    }

    &__line {
      position: absolute;
      left: 5%;
      right: 19%;
      bottom: $space-small;
      display: block;
      height: 1px;
      background-color: $color-light-grey;
      z-index: 0;

      @media (min-width: 550px) {
        right: 25%;
      }

      @include bp-medium {
        right: 5%;
        bottom: 35px;
      }

      &:before,
      &:after {
        content: '';
        position: absolute;
        top: 0;
        display: block;
        height: 100%;
        background-color: $color-smoke;
      }

      &:before {
        left: 0;
      }

      &:after {
        right: 0;
      }
    }
  }
}

.our-process__title {
  text-align: center;

  @media (max-width: $bp-medium) {
    margin-top: $space * 2;
    font-size: 16px;
  }
}

.process-slider--item__icon-wrapper {
  display: inline-block;
  margin-top: -40px;
  padding: $space-small;
  background-color: white;
  border-radius: 50%;

  @include bp-medium {
    margin-top: -61px;
  }
}

.process-slider--item__icon {
  width: 80px;
  height: 80px;

  @include bp-medium {
    width: 123px;
    height: 123px;
  }
}

.process-slider--item {
  height: 100%;
}

.process-slider--item__wrapper {
  height: 100%;
  margin: 0 $space;
  padding-bottom: $space;
  background-color: white;

  @include bp-medium {
    margin: 0 $space * 3;
    padding-bottom: $space * 2;
  }
}

.process-slider--item__text {
  max-width: 460px;
  margin: $space auto 0;
  padding: 0 $space-small * 1.5;

  h3 {
    margin-bottom: $space-small;
  }
}
