.icon {
  &-watch {
    min-height: 24px;
    background-image: url('../assets/images/icons/watch.svg');

    &--link {
      min-height: 24px;
      padding-left: $space * 1.75;
      background: url('../assets/images/icons/watch-link.svg') no-repeat left top 2px;
    }
  }
}
