/* ==========================================================================
   #HEADINGS
   ========================================================================== */

/**
 * Simple default styles for headings 1 through 6. Anything more opinionated
 * than simple font-size changes should likely be applied via classes (see:
 * http://csswizardry.com/2016/02/managing-typography-on-large-apps/).
 */

h1, h2, h3, h4 {
  @extend .serif;
  font-weight: 600;
}

.h1 {
  @extend .serif;
  letter-spacing: -0.59px;
  line-height: 28px;
  font-size: 23px;
}

h1 {
  @extend .serif;
  letter-spacing: -0.59px;
  line-height: 28px;
  font-size: 26px;

  &, .h1 {
    @include bp-medium {
      letter-spacing: -0.77px;
      line-height: 44px;
      font-size: 34px;
    }

    @include bp-large {
      letter-spacing: -0.9px;
      line-height: 51px;
      font-size: 40px;
    }
  }
}


h2, .h2 {
  font-size: 20px;

  @include bp-medium {
    font-size: 30px;
  }

  @include bp-large {
    font-size: 36px;
  }
}

h3, .h3 {
  font-size: 18px;

  @include bp-medium {
    font-size: 27px;
  }
}

h4 {
  font-size: 16px;

  @include bp-medium {
    font-size: 22px;
  }
}
