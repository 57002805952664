.news-stories {
  padding-top: $space * 2;
  padding-bottom: $space * 2;

  @include bp-medium {
    padding-top: $space * 4.5;
    padding-bottom: $space * 4.5;
  }

  &--title {
    margin-bottom: 0;
    color: $color-brown-light;
    font-size: 29px;
    text-align: center;
    text-transform: none;

    @include bp-medium {
      margin-top: $space;
      margin-bottom: 2em;
      font-size: 44px;
    }
  }

  &--item {
    display: flex;
    align-items: stretch;
    margin-top: $space * 1.5;
    padding-left: 7px;
    padding-right: 7px;

    @media (max-width: $bp-large - 1) {
      flex: 0 0 90%;
    }

    @include bp-large {
      padding-left: $space;
      padding-right: $space;
    }

    &:last-of-type {
      padding-right: 14px;
    }

    &s {
      display: flex;
      overflow: auto;
      flex-wrap: nowrap;
      margin-left: -14px;
      margin-right: -14px;

      @include bp-large {
        margin-left: -$space;
        margin-right: -$space;
      }
    }

    &__first {
      margin-left: 7px;

      @include bp-large {
        flex: 0 0 47%;
        margin-left: 0;
      }
    }

    &__thumb {
      display: block;
      height: 200px;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
      transition: $transition;

      @include bp-large {
        height: 350px;
      }

      &:hover {
        opacity: 0.85;
      }

      &-wrapper {
        overflow: hidden;
        background-color: $color-green;
      }
    }

    &__wrapper {
      position: relative;
      width: 100%;
      background-color: $color-smoke;

      &:hover {
        .news-stories--item__thumb {
          transform: scale(1.03);
        }

        .news-stories--item__link {
          color: #181817;
        }
      }
    }

    &__content {
      min-height: 270px;
      padding: $space;
      padding-bottom: $space * 3;

      @include bp-medium {
        padding: $space * 1.5;
        padding-bottom: $space * 2;
      }
    }

    &__category {
      @extend .sans-serif-special;
      margin: 0;
      font-size: 14px;
      letter-spacing: 1.57px;
      color: $color-light-grey;
      text-transform: uppercase;
    }

    &__link {
      position: absolute;
      left: $space;
      bottom: $space;
      font-size: 13px;
      color: $color-text;
      text-transform: none;
      text-decoration: underline;

      @include bp-medium {
        left: $space * 1.5;
        bottom: $space * 1.5;
      }

      &:hover {
        @include bp-medium {
          color: #181817;
        }
      }

      &:after {
        display: none;
      }
    }

    &__title {
      @extend .serif;
      margin-bottom: $space-small;
      font-size: 18px;
      font-weight: $font-bold;
      color: $color-text;
      letter-spacing: initial;
      text-transform: none;
      text-decoration: none;

      @include bp-medium {
        margin-bottom: 1em;
        font-size: 21px;
        line-height: 30px;
      }

      &:after {
        display: none;
      }


      a:after {
        display: none;
      }
    }

    &__preview {
      max-width: 440px;
      font-size: 16px;
      color: $color-text;

      .read-more {
        display: none;
      }
    }
  }

  &--content {
    position: relative;
    overflow: hidden;
  }

  &--all {
    margin-top: $space;
    text-align: center;

    @include bp-medium {
      position: absolute;
      top: 0;
      right: $space;
      margin-top: 0;
    }

    a {
      color: $color-brown-light;

      &:after {
        width: 100%;
      }
    }
  }
}
