.video-section {
  padding-top: $space * 2;
  padding-bottom: $space * 2;
  text-align: center;

  @include bp-medium {
    padding-top: 115px;
    padding-bottom: 115px;
  }

  @include bp-large {
    padding-top: 145px;
    padding-bottom: 145px;
  }

  h2, a {
    color: $color-brown-light;
  }

  h2 {
    max-width: 715px;
    margin-left: auto;
    margin-right: auto;
  }

  p {
    color: $color-smoke;
  }

  &--content {
    max-width: 605px;
    margin-left: auto;
    margin-right: auto;

    a:after {
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &--vid {
    margin-top: $space * 1.5;
    margin-bottom: $space * 1.5;
    text-align: center;

    @include bp-medium {
      margin-top: $space * 4.5;
      margin-bottom: $space * 3.5;
    }

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 100%;
      z-index: 1;
    }

    &__placeholder {
      position: relative;
      display: inline-block;
      width: 100%;
      height: 0;
      padding-bottom: 56.25%;

      &-img {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
      }
    }

    &__watch {
      position: absolute;
      left: 50%;
      top: 50%;
      display: block;
      padding: 0;
      transform: translate(-50%, -50%);
      z-index: 3;
    }
  }
}
