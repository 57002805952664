/* ==========================================================================
   #FONT-FACE
   ========================================================================== */

/**
 * Add font-display: swap to font-face blocks here
 * https://www.zachleat.com/web/comprehensive-webfonts/#font-display
 */

.serif {
  font-family: $font-serif;
}

.sans-serif {
  font-family: $font-sans-serif;
}

.sans-serif-special {
  font-family: $font-sans-serif-special;
}
