.c-404 {
  text-align: center;

  &--content {
    max-width: 700px;
    margin-top: $space-small;
    margin-left: auto;
    margin-right: auto;
  }
}
