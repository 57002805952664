.product {
  &-content {
    @extend .section-smoke;
    position: relative;
    text-align: center;

    @include bp-medium {
      min-height: calc(100vh - #{$header-height});
    }
  }

  &-text {
    padding-right: $space-small * 1.5;
    padding-bottom: $space * 1.5;
  }

  &-desc {
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
    font-size: 14px;

    @include bp-large {
      letter-spacing: -0.45px;
    }
  }

  &-title {
    position: relative;
    margin: 0;
    padding: $space-small;
    color: $color-smoke;
    font-size: 20px;
    z-index: 2;

    @media (min-width: 880px) {
      padding: 18px $space-small;
      font-size: 22px;
    }

    @include bp-large {
      padding: 29px $space-small;
      font-size: 28px;
    }
  }

  &-heading-bg {
    position: relative;

    &:before {
      content: '';
      position: absolute;
      right: 0;
      left: 0;
      top: 0;
      display: block;
      height: 48px;
      z-index: 1;

      @include bp-medium {
        height: 64px;
      }

      @media (min-width: 880px) {
        height: 80px;
      }

      @include bp-large {
        height: 109px;
      }
    }
  }

  &-image {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 100%;
    right: 0;
    overflow: hidden;
    transform: translateZ(0);
    z-index: 2;

    &.active {
      left: 0;

      @include bp-medium {
        &:after {
          opacity: 1;
        }
      }
    }

    &:after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      display: block;
      background-color: white;
      opacity: 0;
      transition: $transition;
      z-index: 1;
    }

    @media (max-width: $bp-medium - 1) {
      background: none !important;
    }

    @include bp-medium {
      position: relative;
      top: auto;
      bottom: auto;
      left: auto;
      right: auto;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
    }
  }

  &--title-lined {
    margin-top: $space * 1.5;
    padding-top: $space * 2;
    padding-bottom: $space * 1.5;

    @include bp-large {
      margin-top: $space * 2.5;
      padding-top: $space * 2.5;
      padding-bottom: $space * 2.25;
    }

    .page--title-lined__small {
      color: $color-brown-light;
    }

    &__no-top {
      position: relative;
      padding-bottom: 0;

      &:after {
        @include bp-medium {
          content: '';
          position: absolute;
          left: 0;
          bottom: 0;
          display: block;
          width: 100%;
          height: $space * 3.5;
          background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(243,241,233,1) 80%); /* FF3.6-15 */
          background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(243,241,233,1) 80%); /* Chrome10-25,Safari5.1-6 */
          background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(243,241,233,1) 80%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
          filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#f3f1e9',GradientType=0 ); /* IE6-9 */
        }
      }

      .page--title-lined__small:before,
      .page--title-lined__small:after {
        display: none;
      }
    }
  }

  &-link {
    @extend .serif;
    padding: 8px;
    font-size: 18px;
    transition: $transition;

    @include bp-large {
      padding: $space-small;
      font-size: 27px;
      letter-spacing: -0.61px;
    }

    &.not-active {
      opacity: 0.5;
    }

    &.active {
      opacity: 1;
    }

    &:hover {
      color: black;
    }

    &__wrapper {
      margin: 0;

      &:last-of-type {
        padding-bottom: $space;

        @include bp-medium {
          padding-bottom: $space * 3;
        }
      }
    }
  }

  &-enquire {
    margin-top: $space - 3;
    margin-bottom: $space - 3;

    @include bp-medium {
      margin-top: $space * 2;
      margin-bottom: $space * 2;
    }
  }

  &-details {
    position: absolute!important;
    left: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    background-color: white;
    opacity: 0;
    overflow: auto;
    transition: $transition;
    z-index: 2;

    @include bp-medium {
      top: 0;
    }

    &.active {
      opacity: 1;
      z-index: 3;
    }

    &__img {
      width: 100%;
      height: 50%;
      min-height: 50vh;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
    }

    &__stamp {
      display: none;

      @include bp-medium {
        position: absolute;
        top: 50%;
        right: $space * 3;
        display: block;
        width: 80px;
        height: 80px;
        transform: translateY(-50%);
      }

      @include bp-large {
        width: 120px;
        height: 120px;
      }

      @include bp-full {
        width: 151px;
        height: 151px;
      }
    }

    &__content {
      padding: $space;

      @include bp-medium {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: $space;
        padding-bottom: $space * 3;
        overflow: initial;
      }
    }

    &__subtitle {
      @extend .page--title-lined__clear;
      opacity: 0.33;
    }

    &__title {
      margin-bottom: $space-small * 1.5;

      @include bp-large {
        margin-bottom: $space * 2;
        font-size: 33px;
      }
    }

    &__table {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      text-align: left;
      border-top: 1px solid $color-very-light-grey;
      border-bottom: 1px solid $color-very-light-grey;

      &-item {
        padding: $space-small * 1.5 $space-small * 1.5;

        @include bp-large {
          padding: $space-small * 1.5 $space * 1.25;
        }

        &.process {
          display: flex;
          justify-content: center;
          align-items: center;
          flex: 0 0 100%;
          border-bottom: 1px solid $color-very-light-grey;

          @include bp-large {
            flex: auto;
            border-bottom: none;
          }
        }

        &.size {
          position: relative;

          &:before,
          &:after {
            content: '';
            position: absolute;
            top: $space-small;
            bottom: $space-small;
            display: block;
            width: 1px;
            background-color: $color-very-light-grey;
          }

          &:before {
            display: none;
            left: 0;

            @include bp-large {
              display: block;
            }
          }

          &:after {
            right: 0;
          }
        }
      }
    }

    &__heading {
      @extend .page--title-lined__clear;
      margin-bottom: 0;
      opacity: 0.5;
      text-align: left;
    }

    &__icon {
      margin-right: $space;
      width: 46px;
      height: 46px;
    }

    &__text {
      font-size: 17px;
    }

    &__desc {
      max-width: 450px;
      margin-top: $space-small * 1.5;
      margin-left: auto;
      margin-right: auto;
      font-size: 15px;

      @include bp-large {
        margin-top: $space * 1.5;
      }
    }

    &__link {
      margin-top: $space-small * 1.5;

      @include bp-large {
        margin-top: $space * 1.5;
      }
    }

    &__close {
      position: fixed;
      top: 50px;
      right: 0;
      width: 50px;
      height: 50px;
      border: none;
      outline: none;
      background: url('../assets/images/icons/close.png') no-repeat center center;
      background-size: $space $space;
      z-index: 2;

      @include bp-medium {
        display: none;
      }
    }
  }

  .product-details__heading {
    font-size: 15px;
    color: $color-text;

    @include bp-large {
      font-size: 18px;
    }
  }

  // sustainable
  &-sustainable {
    .product-heading-bg:before {
      background: $color-sustainable url('../assets/images/sustainable-bg.jpg');
      background-size: cover;
    }
  }

  &-organic {
    .product-heading-bg:before {
      background: $color-organic url('../assets/images/organic-bg.jpg');
      background-size: cover;
    }
  }

  &__bottom-logos {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    div {
      padding: $space;
    }

    img {
      width: 100%;
    }
  }
}

.page-template-page-product {
  height: 100%;
  background-color: $color-smoke;
}
