.main-content {
  transition: opacity 0.65s ease-in-out, transform 0.65s ease-in-out;
  opacity: 0;

  @include bp-medium {
    height: 100%;
    overflow: hidden;
    transform: translate3d(0,0,0) scale(1.025);
  }

  &.loaded {
    opacity: 1;

    @include bp-medium {
      transform: translate3d(0,0,0) scale(1);
    }
  }

  &--wrapper {
    padding-top: 50px;

    @include bp-medium {
      padding-top: $header-height;
    }
  }
}
