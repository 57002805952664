/* ==========================================================================
   #LAYOUT
   ========================================================================== */

/* Simple flexbox layout system */

.o-layout {
  display: block;

  @include bp-medium {
    display: flex;
  }
}

.o-layout__item {
  flex: 1;
}

.o-layout--guttered {
  justify-content: space-between;
}

.o-layout--middle {
  align-items: center;
}

.o-layout--2 {
  flex-wrap: wrap;

  > .o-layout__item {
    @include bp-medium {
      flex: 0 0 50%;
      width: 50%;
    }
  }
}
