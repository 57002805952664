/* ==========================================================================
   #LINKS
   ========================================================================== */

.link,
a {
  color: $color-brown;
  text-decoration: none;
  outline: none;
  transition: $transition;

  &:hover {
    color: $color-hover;
  }
}

p a,
p .link {
  @extend .sans-serif-special;
  position: relative;
  display: inline-block;
  margin-top: $space-small;
  padding-bottom: 3px;
  font-size: 16px;
  text-transform: uppercase;
  color: $color-brown;

  @include bp-large {
    padding-bottom: 5px;
    font-size: 20px;
    letter-spacing: 1px;
  }

  &:after {
    content: '';
    position: absolute;
    left: 50%;
    bottom: 0;
    display: block;
    width: 100%;
    height: 1px;
    background-color: currentColor;
    transform: translate(-50%, -4px);
    transition: $transition;
  }

  &:hover {
    color: $color-brown;

    &:after {
      width: 100%;
      transform: translate(-50%, -7px);
    }
  }
}
