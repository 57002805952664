.our-mill {
  .our-process--it {
    @media (max-width: $bp-medium - 1) {
      margin-top: 0;
      flex-direction: column;
    }
  }

  .slick-track {
    display: flex;
    align-items: stretch;
  }

  .slick-slide {
    height: auto;
    text-align: center;

    > div {
      height: 100%;
    }
  }

  &-content {
    padding-top: $space;

    @include bp-medium {
      padding-top: $space * 3;
    }

    @include bp-large {
      padding-top: $space * 6;
    }

    h2 {
      margin-bottom: $space-small;

      @include bp-medium {
        margin-bottom: $space;
      }

      @include bp-large {
        font-size: 45px;
      }
    }

    h4 {
      margin-top: $space;
      margin-bottom: $space-small;

      @include bp-medium {
        margin-top: $space * 2;
        font-size: 21px;
      }
    }

    p {
      font-size: 15px;
      line-height: 26px;
    }

    p + p {
      margin-top: $space;
    }
  }

  &--it__item {
    flex: 0 0 38%;
    text-align: left;
  }

  &-slider {
    &--wrapper {
      padding-top: $space * 3;
      padding-bottom: $space * 3;

      @include bp-medium {
        padding-top: $space * 6;
        padding-bottom: $space * 6;
      }

      @include bp-large {
        padding-top: $space * 7.25;
        padding-bottom: $space * 7.25;
      }
    }

    &--heading {
      position: static;
      text-align: center;
      color: $color-brown;
      transform: none;

      &:after {
        background-color: #D6CEC2;

        @include bp-medium {
          margin-bottom: $space * 2;
        }
      }
    }

    &--item {
      max-width: 800px;
      height: 100%;
      text-align: center;
      opacity: 0.5;
      transition: $transition;

      .slick-current & {
        opacity: 1;
      }

      &__wrapper {
        height: 100%;
        padding: 0 2px;

        @include bp-medium {
          padding: 0 53px;
        }
      }

      &__content {
        height: 100%;
        padding-bottom: $space;
        background-color: white;
        overflow: hidden;

        @include bp-medium {
          padding-bottom: $space * 2.5;
        }

        > img {
          @media (max-width: $bp-medium - 1) {
            max-width: 150%;
            width: 150%;
            margin-left: -25%;
          }
        }
      }

      &__text {
        max-width: 470px;
        margin-left: auto;
        margin-right: auto;
        margin-top: $space * -3;
        padding: 0 $space;

        h3 {
          @include bp-medium {
            margin-bottom: $space-small;
          }
        }

        p {
          font-size: 15px;
        }
      }

      &__icon {
        display: inline-block;
        width: 118px;
        border: 11px solid white;
        border-radius: 50%;
        background-color: white;

        @include bp-large {
          width: 143px;
        }

        &-wrapper {
          transform: translateY(-50%);
        }
      }
    }

    .c-slider--prev,
    .c-slider--next {
      @extend .custom-arrow;

      @include bp-medium {
        display: none;
      }

      @media (min-width: 880px) {
        position: absolute;
        top: 50%;
        bottom: auto;
        display: block;
      }

      @media (min-width: 880px) and (max-width: $bp-large - 1) {
        margin-left: 0 !important;
        margin-right: 0 !important;
      }
    }

    .c-slider--prev {
      @media (min-width: $bp-medium) and (max-width: $bp-large - 1) {
        left: $space;
      }
    }

    .c-slider--next {
      @media (min-width: $bp-medium) and (max-width: $bp-large - 1) {
        right: $space;
      }
    }
  }

  &-content-dark {
    padding-top: $space * 2.5;
    padding-bottom: $space * 2.5;
    text-align: center;

    @include bp-medium {
      padding-top: $space * 6.5;
      padding-bottom: $space * 5;
      text-align: left;
    }

    @include bp-medium {
      padding-top: $space * 8.5;
      padding-bottom: $space * 7;
    }

    h2 {
      color: $color-brown;
    }

    p {
      color: $color-smoke;
      font-size: 15px;
    }

    &--img {
      margin-top: $space;

      @include bp-medium {
        margin-top: 0;
      }
    }

    &--text {
      margin-left: auto;
      margin-right: auto;

      @include bp-medium {
        margin-left: 0;
      }
    }

    &--1 {
      .process-content-dark--text {
        max-width: 360px;
      }

      .process-content-dark--img {
        margin-top: $space;

        @include bp-medium {
          margin-top: $space * 3.5;
        }
      }
    }

    &--2 {
      .process-content-dark--text {
        max-width: 480px;
        margin-top: $space * 1.5;

        @include bp-medium {
          margin-top: $space * 2.5;
        }

        @include bp-large {
          margin-top: $space * 3.5;
        }
      }

      .process-content-dark--img {
        display: none;

        @include bp-medium {
          display: block;
          margin-left: auto;
        }
      }
    }
  }

  &--it {
    img {
      margin: $space * 1.75 0;
    }

    &__item {
      @media (max-width: $bp-medium - 1) {
        margin-top: 0;
      }
    }
  }
}

.our-mill__top-content__wrapper {
  max-width: 765px;
  padding-top: $space * 2;
  padding-bottom: $space;
  text-align: center;

  @include bp-medium {
    padding-top: $space * 4;
    padding-bottom: $space * 2;
  }

  @include bp-large {
    padding-top: $space * 8;
  }

  h3 {
    line-height: 1.5;

    @include bp-medium {
      font-size: 25px;
    }
  }
}

.our-mill__wrapper {
  max-width: 1480px;
}

.our-mill__it-content {
  max-width: 415px;
  margin: 0 auto;

  h2 {
    margin-bottom: $space-small;

    @include bp-medium {
      margin-bottom: $space;
    }
  }
}

.our-mill--content {
  padding-bottom: $space * 2;

  @include bp-medium {
    padding-bottom: $space * 4;
  }

  @include bp-large {
    padding-bottom: $space * 7.5;
  }
}

.c-slider--mill__nav {
  max-width: 725px;
  margin: 0 auto $space * 2;

  @include bp-medium {
    margin-bottom: $space * 5;
  }

  .slick-slide {
    pointer-events: initial;
  }
}

.c-slider--mill__btn {
  display: block;
  font-family: $font-serif;
}

.c-slider--mill__btn-step {
  font-size: 12px;
  text-transform: uppercase;
  color: white;
  letter-spacing: 0.41px;
}

.c-slider--mill__btn-title {
  font-size: 20px;
  color: $color-smoke;
  letter-spacing: 0.75px;

  .slick-current & {
    color: $color-brown-light;
  }
}

.c-slider--mill__btn-step,
.c-slider--mill__btn-title {
  margin: 0;
  line-height: 1.2;
  opacity: 0.28;
  transition: $transition;

  .slick-current & {
    opacity: 1;
  }
}

.c-slider--mill__nav-item {
  position: relative;

  &:after {
    content: '';
    position: absolute;
    left: 100%;
    top: 50%;
    display: block;
    width: 47px;
    height: 1px;
    background-color: #3B3730;
    transform: translateX(-50%);
  }
}

.slick-slide:last-child .c-slider--mill__nav-item:after {
  display: none;
}

.our-mill__slider-wrapper {
  position: relative;
  padding-top: $space * 2;
  padding-bottom: $space * 2;
  background: $color-dark url('../assets/images/journey-of-our-grain-bg.jpg') no-repeat center bottom;
  background-size: 100% auto;

  @include bp-medium {
    padding-top: $space * 4;
    padding-bottom: $space * 5;
  }

  @include bp-large {
    padding-top: $space * 6;
    padding-bottom: $space * 11;
  }

  &:before {
    @include bp-medium {
      content: '';
      position: absolute;
      top: -40px;
      left: 50%;
      display: block;
      width: 1px;
      height: 80px;
      background-color: $color-brown-light;
    }
  }

  .c-slider--prev,
  .c-slider--next {
    top: 50%;
    width: 58px;
    padding: 0;
    background: none;

    @media (max-width: $bp-large - 1) {
      display: none !important;
    }

    svg path {
      fill: $color-brown;
      transition: $transition;
    }

    &:hover {
      svg path {
        fill: $color-text;
      }
    }
  }
}

.our-mill__slider-title {
  margin-bottom: $space;
  padding: 0 $space;
  color: $color-brown-light;
  text-align: center;

  @include bp-medium {
    margin-bottom: $space * 3;
    font-size: 30px;
  }
}

.c-slider--mill .slick-slide {
  opacity: 0.5;
  transition: opacity 0.3s ease;
}

.c-slider--mill .slick-slide.slick-current {
  opacity: 1;
}
