.contact {
  h1 {
    @extend .h3;
  }

  p a {
    letter-spacing: initial;
  }

  &-top {
    padding-bottom: $space * 2;
    background-color: $color-brown-very-light;

    @include bp-medium {
      padding-top: $space * 5;
      padding-bottom: $space * 4.5;
    }

    @include bp-medium {
      padding-top: $space * 6.5;
      padding-bottom: $space * 6.5;
    }

    &--layout {
      display: flex;
      flex-direction: column-reverse;

      @include bp-medium {
        flex-direction: row;
      }
    }
  }

  &-bottom {
    padding-top: $space * 1.75;
    padding-bottom: $space * 1.75;
    background-color: $color-smoke;

    @include bp-medium {
      padding-top: $space * 3.5;
      padding-bottom: $space * 3.5;
    }

    @include bp-medium {
      padding-top: $space * 5;
      padding-bottom: $space * 5;
    }
  }

  &-item {
    margin-top: $space;

    @include bp-medium {
      margin-top: $space * 1.5;
    }

    a {
      @extend .serif;
      margin-top: 0;
      padding: 0;
      color: $color-text;
      font-size: inherit;
      font-weight: $font-bold;
      line-height: 1;
      text-transform: none;

      &:after {
        display: none;
      }
    }
  }

  &-items {
    p {
      @extend .serif;
      margin: 0;
      letter-spacing: -0.36px;
    }

    &--label {
      font-size: 15px;
      opacity: 0.44;

      @include bp-medium {
        font-size: 16px;
      }
    }

    &--text {
      font-size: 15px;

      @include bp-medium {
        font-size: 17px;
      }
    }
  }


  &-left {
    flex: 0 0 335px;

    &--content {
      font-size: 14px;

      @include bp-medium {
        font-size: 15px;
      }

      h3 {
        margin-bottom: $space;

        @include bp-medium {
          margin-bottom: $space * 1.25;
        }
      }
    }
  }

  &-right {
    flex: 1;
  }

  &-map {
    height: 225px;
    max-height: 50vh;
    margin-left: $space * -0.75;
    margin-right: $space * -0.75;
    margin-bottom: $space * 2;

    @include bp-medium {
      max-width: 640px;
      height: 100%;
      margin-left: auto;
      margin-right: 0;
      margin-bottom: 0;
    }
  }

  &-download {
    margin-top: 0;

    &:after {
      display: none;
    }
  }

  .gform_confirmation_wrapper {
    max-width: 640px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }

  .gform_wrapper {
    @extend .serif;
    max-width: 640px;

    @include bp-medium {
      margin-left: auto;
    }

    h3.gform_title {
      @extend p;
      @extend .serif;
      margin: initial;
      margin-top: 0;
      font-size: 17px;
      font-weight: $font-normal;
    }

    ul.gform_fields li.gfield {
      padding: 0;
      transition: $transition;

      &.gfield_error {
        padding-bottom: $space-small;
      }
    }

    .top_label .gfield_label {
      display: none;
    }

    textarea.medium,
    .top_label input.medium, .top_label select.medium,
    input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]) {
      width: 100%;
      padding: $space * 0.75 $space-small;
      border: none;
      border: 1px solid #C0BFB7;
      background: none;
      outline: none;
      font-size: 14px;
      font-weight: $font-normal;

      @include bp-medium {
        padding: $space * 0.75;
        font-size: 15px;
      }
    }

    .gfield_error textarea.medium {
      margin-bottom: 0;
    }

    .top_label input.medium, .top_label select.medium {
      width: 100%;
    }

    select option {
      padding: 0;
      background-color: $color-smoke;
    }

    .ginput_container_select {
      border: 1px solid #C0BFB7;
    }

    .top_label select.medium.gfield_select {
      border: none;
      -moz-appearance: none;
      -webkit-appearance: none;
      appearance: none;
      background: url('../assets/images/icons/select-arrow.png') no-repeat right $space center;

      &.placeholder {
        opacity: 0.65;
      }
    }

    .gform_footer input.button, .gform_footer input[type=submit], .gform_page_footer input.button, .gform_page_footer input[type=submit] {
      @extend .c-btn;
      @extend .c-btn--full;
      height: 100%;
      margin-right: 0;
      background-color: $color-brown;
      border: none;
      outline: none;
      cursor: pointer;

      &:hover {
        @include bp-medium {
          background-color: $color-brown-light;
          color: white;
        }
      }
    }

    ul li.gfield {
      position: relative;
      margin-top: 0;
    }

    .gform_fields {
      @include bp-medium {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
      }
    }

    .top_label .gfield_error,
    .gfield {
      max-width: 100% !important;

      @include bp-medium {
        width: 47%;
      }

      &:nth-last-child(2) {
        @include bp-medium {
          width: 100%;
        }
      }
    }

    li.gfield.gfield_error, li.gfield.gfield_error.gfield_contains_required.gfield_creditcard_warning {
      border: none;
      background: none;
    }

    &.gform_validation_error .top_label input.medium, &.gform_validation_error .top_label select.medium {
      width: 100%;
    }

    .gform_footer {
      margin-top: $space-small;
      padding: 0;
    }

    li.gfield_error input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]), li.gfield_error textarea {
      border-color: $color-organic;
    }

    .field_description_below .gfield_description {
      position: absolute;
      left: 0;
      bottom: -$space-small;
      padding: 0;
      font-size: 13px;
    }

    div.validation_error {
      margin: 0;
      padding: 0;
      font-size: 15px;
      font-weight: $font-normal;
      color: $color-organic;
      text-align: left;
      border: none;
    }

    .validation_message {
      color: $color-organic;
    }
  }

  ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: rgba($color-text, 0.65);
  }
  ::-moz-placeholder { /* Firefox 19+ */
    color: rgba($color-text, 0.65);
  }
  :-ms-input-placeholder { /* IE 10+ */
    color: rgba($color-text, 0.65);
  }
  :-moz-placeholder { /* Firefox 18- */
    color: rgba($color-text, 0.65);
  }
}
