.our-products {
  position: relative;
  margin-top: -2px;
  padding-top: $space * 2;
  padding-bottom: $space * 2;

  @include bp-medium {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  &:after {
    content: '';
    position: absolute;
    left: 50%;
    bottom: -50px;
    display: block;
    width: 1px;
    height: 100px;
    background-color: $color-brown-light;
  }

  .page--title-lined {
    border-bottom: none;

    h2 {
      max-width: 360px;
      margin-bottom: 0;

      @include bp-medium {
        margin-bottom: $space;
      }
    }
  }

  &--item {
    display: flex;
    align-items: stretch;
    margin-top: $space;
    padding: 0 $space;
    text-align: center;

    &__wrapper {
      position: relative;
      display: block;
      padding-bottom: $space * 4;
      color: $color-text;
      background-color: white;

      @include bp-medium {
        padding-bottom: 115px;
      }

      &:hover {
        color: $color-text;

        .link:after {
          height: 3px;
        }

        img {
          transform: scale(1.05);
        }
      }
    }

    &__image {
      overflow: hidden;

      img {
        transform-origin: center center;
        transition: 0.5s ease-in-out;
      }
    }

    &__title {
      position: relative;
      display: inline-block;
      width: 100%;
      max-width: 240px;
      margin: 0;
      padding: $space + 4 $space-small;
      @extend .serif;
      font-size: 19px;
      line-height: 1;
      color: white;
      transform: translateY(-50%);

      &:after {
        content: '';
        position: absolute;
        top: 4px;
        bottom: 4px;
        left: 4px;
        right: 4px;
        display: block;
        border: 1px solid white;
      }
    }

    &__content {
      max-width: 450px;
      margin-left: auto;
      margin-right: auto;
      padding-left: $space;
      padding-right: $space;
    }

    &s {
      margin-left: $space-small * -1.5;
      margin-right: $space-small * -1.5;
    }

    &.product-1 {
      .our-products--item__title {
        background-color: $color-sustainable;

        &:after {
          border: 1px solid lighten($color-sustainable, 10%);
        }
      }
    }

    &.product-2 {
      .our-products--item__title {
        background-color: $color-organic;

        &:after {
          border: 1px solid lighten($color-organic, 10%);
        }
      }
    }
  }

  &--link {
    position: absolute;
    left: 50%;
    bottom: $space * 1.5;
    transform: translateX(-50%);

    @include bp-medium {
      bottom: 45px;
    }

    a:after {
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &--content {
    color: $color-brown-very-light;
  }

  &--wrapper {
    max-width: 620px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }

  &--logo {
    width: auto;
    margin-top: $space;
    padding: 0 $space-small;

    @include bp-medium {
      margin-top: $space * 1.5;
    }

    img {
      width: 100%;
      padding: $space-small $space-small * 4;

      @include bp-medium {
        max-height: 70px;
        padding: 0;
      }
    }

    &s {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      @include bp-medium {
        justify-content: space-between
      }
    }
  }
}
