/* ==========================================================================
   #WRAPPER
   ========================================================================== */

/**
 * Page-level constraining and wrapping elements.
 */

.o-wrapper {
  @include clearfix();
  margin-right: auto;
  margin-left: auto;
  max-width: 1200px;
  padding-left: $space-small * 1.5;
  padding-right: $space-small * 1.5;

  @include bp-medium {
    padding-left: $space * 1.5;
    padding-right: $space * 1.5;
  }

  @include bp-large {
    padding-left: $space-small * 1.5;
    padding-right: $space-small * 1.5;
  }

  &--medium {
    @include bp-xlarge {
      max-width: 1350px;
    }
  }

  &--large {
    @include bp-xlarge {
      max-width: 1400px;
    }
  }

  &--small {
    max-width: 1030px;
  }

  &--half {
    max-width: 900px;
    margin-right: 0;
    padding-right: 0;
  }
}
