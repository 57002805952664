.lazy-load {
  display: inline-block;
  transition: $transition;

  @include bp-medium {
    opacity: 0;
  }

  &.show {
    opacity: 1;
  }
}
