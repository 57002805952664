.faq {
  padding-top: $space * 2;
  background-color: $color-smoke;

  @include bp-medium {
    padding-top: $space * 4;
  }

  @include bp-large {
    padding-top: $space * 6;
  }

  .page--title-lined h2 {
    max-width: 100%;

    @include bp-medium {
      margin-bottom: $space;
    }
  }

  &-item {
    position: relative;
    border-top: 1px solid $color-very-light-grey;

    &:last-of-type {
      border-bottom: 1px solid $color-very-light-grey;
    }

    &__toggle {
      position: relative;
      width: 100%;
      padding-top: $space-small * 1.5;
      padding-bottom: $space-small * 1.5;
      padding-right: $space * 2;
      padding-left: 0;
      text-align: left;

      @include bp-medium {
        font-size: 21px;
      }

      &:before,
      &:after {
        content: '';
        position: absolute;
        top: 50%;
        right: $space-small;
        display: block;
        background-color: rgba($color-text, 0.38);
        transform: translateY(-50%);
        transition: $transition;

        @include bp-medium {
          right: $space;
        }
      }

      &:before {
        width: 2px;
        height: 16px;
        margin-right: 7px;
      }

      &:after {
        width: 16px;
        height: 2px;
      }

      &.active {
        &:before {
          opacity: 0;
        }
      }
    }

    &__content {
      display: none;
      max-width: 680px;
      padding-bottom: $space-small * 1.5;
      font-size: 14px;
      line-height: 26px;

      @include bp-medium {
        font-size: 15px;
      }
    }
  }

  &-more {
    position: relative;
    margin-top: $space * 2.25;
    margin-left: $space * -1.25;
    margin-right: $space * -1.25;
    padding: $space * 2 $space * 1.5;
    text-align: center;

    @include bp-medium {
      margin-top: $space * 3.5;
      margin-left: 0;
      margin-right: 0;
      padding: $space * 3.25 $space * 1.5;
    }

    &:after,
    &:before {
      content: '';
      position: absolute;
      display: block;
      z-index: 0;
    }

    &:before {
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      border: 1px solid $color-very-light-grey;
    }

    &:after {
      left: 7px;
      right: 7px;
      top: 7px;
      bottom: 7px;
      background-color: $color-brown-very-light;
    }

    &__wrapper {
      position: relative;
      z-index: 1;
    }

    &__title {
      margin-bottom: $space-small * 1.5;
      font-size: 22px;

      @include bp-medium {
        margin-bottom: $space;
        font-size: 27px;
      }
    }
  }

  &-link {
    margin-top: $space;
  }
}
