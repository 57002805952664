.export {
  padding-top: $space * 1.75;
  padding-bottom: $space * 1.75;
  background-color: $color-green;
  color: $color-smoke;

  @include bp-medium {
    padding-top: $space * 4.75;
    padding-bottom: $space * 2.5;
  }

  @include bp-large {
    padding-top: $space * 5.75;
    padding-bottom: $space * 3.75;
  }

  .page--title-lined__small {
    color: $color-brown-light;
  }

  .page--title-lined h2 {
    max-width: 100%;

    @include bp-medium {
      margin-bottom: $space * 1.5;
    }
  }

  &-map {
    position: relative;
    padding-top: $space * 1.5;
    margin-bottom: $space * 1.5;

    @include bp-medium {
      padding-top: $space * 4;
      margin-bottom: $space * 6;
    }

    @include bp-large {
      padding-top: $space * 5;
      margin-bottom: $space * 7.5;
    }

    &__img {
      width: 100%;
    }

    .point {
      display: none;

      @include bp-medium {
        position: absolute;
        display: block;
        width: $space;
        height: $space;
        background: url('../assets/images/icons/export-map-marker.svg') no-repeat center center;
        background-size: contain;

        &:hover {
          .point-label {
            left: 50%;
            transform: translate(-50%, 0);
            opacity: 1;
          }
        }
      }

      &.hide {
        display: none !important;
      }

      &-label {
        @extend .sans-serif-special;
        font-size: 18px;
        letter-spacing: 1.15px;

        @include bp-medium {
          position: absolute;
          left: -9999px;
          bottom: 100%;
          margin-bottom: $space-small;
          padding: $space-small / 2 $space-small;
          white-space: nowrap;
          border-radius: 3px;
          opacity: 0;
          cursor: default;
          color: $color-text;
          background-color: rgba(white, 0.84);
          box-shadow: 0 0 12px 0 rgba(black,0.25);
          transform: translate(-50%, $space-small * -0.5);
          transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
        }
      }

      &-label__arrow-wrapper {
        position: absolute;
        top: 100%;
        left: 50%;
        display: block;
        width: 14px;
        height: 7px;
        transform: translateX(-50%);
        overflow: hidden;
      }

      &-label__arrow {
        display: block;
        width: 11px;
        height: 11px;
        margin-top: -6px;
        margin-left: 2px;
        background-color: rgba(white, 0.84);
        box-shadow: 0 0 12px 0 rgba(black,0.25);
        border-bottom-right-radius: 3px;
        transform: rotate(45deg);
      }
    }

    .point-1 {
      right: 20.5%;
      top: 60%;
    }

    .point-2 {
      left: 37%;
      top: 30%;
    }

    .point-3 {
      left: 45%;
      top: 40%;
    }

    .point-4 {
      left: 16.5%;
      top: 50%;
    }

    .point-5 {
      right: 20.5%;
      top: 51.5%;
    }

    .point-6 {
      left: 55.5%;
      top: 72%;
    }

    .point-7 {
      left: 31.5%;
      top: 76%;
    }

    .point-8 {
      right: 10%;
      top: 85%;
    }

    .point-9 {
      right: 3.5%;
      bottom: 1.5%;
    }
  }

  &-text {
    &--title {
      margin-bottom: $space;

      @include bp-medium {
        font-size: 32px;
      }
    }

    &--content {
      font-size: 14px;

      @include bp-medium {
        font-size: 15px;
      }
    }

    &--enquiries {
      @extend .serif;
      margin-top: $space * 2;
      margin-bottom: $space;
      font-size: 17px;
    }

    &--contact {
      @extend .sans-serif-special;

      .label {
        padding-right: $space-small;
        font-size: 16px;
        vertical-align: middle;

        @include bp-medium {
          font-size: 18px;
        }
      }

      .text {
        font-size: 16px;
        color: $color-brown;
        vertical-align: middle;

        @include bp-medium {
          font-size: 20px;
        }
      }
    }
  }

  &-certifications {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    &--img {
      width: 50%;
      padding: $space-small;
    }

    &--wrapper {
      max-width: 290px;
      margin-top: $space;
      margin-left: auto;
      margin-right: auto;
      text-align: center;

      @include bp-medium {
        margin-top: 0;
        margin-right: 0;
      }

      h4 {
        margin-bottom: $space;
      }
    }
  }
}
