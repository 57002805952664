.archive,
.blog {
  background-color: $color-green;

  .news-stories--item {
    height: 100%;
    margin-top: 0;

    &, &:last-of-type {
      padding-left: 0;
      padding-right: 0;

      @include bp-medium {
        padding-left: $space;
        padding-right: $space;
      }
    }
  }

  .news-stories--item__content {
    @media (max-width: $bp-medium - 1) {
      min-height: 0;
    }
  }

  &-page {
    padding-top: $space * 2;
    padding-bottom: $space * 2.5;

    @include bp-medium {
      padding-top: $space * 4;
      padding-bottom: $space * 4;
    }

    @include bp-large {
      padding-top: $space * 6.5;
      padding-bottom: $space * 7.25;
    }

    &--heading {
      color: $color-smoke;
      text-align: center;

      @include bp-large {
        font-size: 45px;
      }
    }
  }

  &-item {
    padding-top: $space;

    @include bp-medium {
      padding-top: $space * 1.5;
    }

    @include bp-large {
      padding-top: $space * 3;
    }
  }

  &-filters {
    margin-bottom: $space;
    text-align: center;

    @include bp-medium {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      margin-bottom: $space * 1.75;
    }

    @include bp-large {
      margin-bottom: $space * 2.5;
    }

    &--item {
      @extend .sans-serif-special;
      margin-left: $space-small;
      margin-right: $space-small;
      margin-bottom: 0;

      a {
        &, &:hover {
          color: $color-smoke;
        }

        &:after {
          opacity: 0;
        }

        &:hover:after {
          opacity: 1;
        }
      }

      &.active {
        a:after {
          opacity: 1;
        }
      }

      &s {
        display: none;

        @include bp-medium {
          display: flex;
          flex-wrap: wrap;
        }
      }
    }

    &--label {
      padding-top: 2px;
      color: $color-brown-light;

      span {
        @include bp-medium {
          display: none;
        }
      }
    }
  }
}
