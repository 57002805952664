.page {
  &--title-lined {
    position: relative;
    padding-top: $space * 2;
    padding-bottom: $space-small;
    border-bottom: 1px solid $color-very-light-grey;
    text-align: center;
    overflow: hidden;

    h2 {
      max-width: 400px;
      margin-bottom: $space;
      margin-left: auto;
      margin-right: auto;

      @include bp-medium {
        font-size: 34px;
      }

      @include bp-large {
        margin-bottom: 1em;
        font-size: 45px;
      }
    }

    &__small {
      @extend .sans-serif-special;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      display: block;
      font-size: 15px;
      letter-spacing: 1.15px;
      white-space: nowrap;
      text-transform: uppercase;

      @include bp-large {
        font-size: 18px;
      }

      &:before,
      &:after {
        content: '';
        position: absolute;
        top: 48%;
        display: block;
        width: 600px;
        height: 1px;
        background-color: $color-very-light-grey;
      }

      &:before {
        right: 100%;
        margin-right: $space;
      }

      &:after {
        left: 100%;
        margin-left: $space;
      }
    }

    &__clear {
      @extend .page--title-lined__small;
      position: static;
      margin-bottom: 5px;
      transform: none;

      &:before,
      &:after {
        display: none;
      }
    }
  }

  &--title {
    &__small {
      @extend .sans-serif-special;
      display: block;
      font-size: 18px;
      text-transform: uppercase;
      letter-spacing: 1.57px;
      text-align: center;
    }
  }
}
