.section {
  &-smoke {
    background-color: $color-smoke;
  }

  &-dark {
    background-color: $color-dark;
  }

  &-white {
    background-color: white;
  }

  &-green {
    background-color: $color-green;
  }
}
