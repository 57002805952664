/* ==========================================================================
   #PAGE
   ========================================================================== */

html {
  height: 100%;
  background: $color-bg;
  color: $color-text;
  font-family: $font-sans-serif;
  font-size: 14px;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  overflow: auto;

  @include bp-medium {
    overflow: hidden;
  }
}

