p {
  margin-bottom: $space-small;
  line-height: 1.57;
  letter-spacing: 0.5px;

  @include bp-medium {
    line-height: 1.75;
  }

  &:last-child {
    margin-bottom: 0;
  }
}
