/* ==========================================================================
   #IMAGES
   ========================================================================== */

img {
  font-style: italic;
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}
