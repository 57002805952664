.story {
  &-content {
    padding-bottom: $space * 1.5;

    @include bp-medium {
      padding-bottom: $space-small * 11.5;
    }

    @include bp-large {
      padding-bottom: $space-small * 16;
    }

    &--it {
      &:first-of-type {
        padding-top: $space;

        @include bp-medium {
          padding-top: 200px;
        }

        @include bp-large {
          padding-top: 275px;
        }
      }

      &__item {
        text-align: left;
      }

      &:first-of-type {
        margin-top: 0;
      }

      &__content {
        max-width: 470px;
        margin-left: 0;

        h2 {
          margin-bottom: $space * 1.5;

          @include bp-medium {
            margin-bottom: $space;
          }

          @include bp-large {
            margin-bottom: $space * 1.25;
            font-size: 45px;
          }
        }

        p:last-of-type a {
          display: block;
          max-width: 180px;
          margin-left: auto;
          margin-right: auto;
        }
      }

      &.odd {
        .story-content--it__content {
          margin-left: 0;

          @include bp-medium {
            padding-right: $space;
          }

          @include bp-xlarge {
            padding-right: 0;
          }
        }
      }

      &.even {
        .story-content--it__content {
          margin-right: 0;
          margin-left: auto;

          @include bp-medium {
            padding-left: $space;
          }

          @include bp-xlarge {
            padding-left: 0;
          }
        }
      }
    }
  }
}
