.modal {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99;

  &--backdrop {
    width: 100%;
    height: 100%;
    background-color: rgba($color-dark, 0.9);
  }

  &--content {
    text-align: center;
    opacity: 0;
    transform: translateY(-10%);
    transition: $transition;

    .active & {
      opacity: 1;
      transform: translateY(0);
    }
  }

  &--content__wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    transform: translate(-50%, -50%);
  }

  &--video-wrapper {
    position: relative;
    display: inline-block;
    width: 100%;
    height: 0;
    padding-top: 56.25%;

    > * {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }
  }
}
