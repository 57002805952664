///* ========================================================================
//   #GLOBAL
//   ======================================================================== */
// The global settings file contains any project-wide variables; things that
// need to be made available to the entire codebase.

$transition: all 300ms ease-in-out !default;

/* Fonts
   ========================================================================== */
@import url('https://use.typekit.net/oxr3skw.css');
$font-serif: "alverata", serif;
$font-sans-serif: "halyard-display", -apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI',
  'Roboto', 'Helvetica Neue', 'Arial', sans-serif !default;
$font-sans-serif-special: "rift", sans-serif;

$font-normal: 300;
$font-semibold: 400;
$font-bold: 600;

/* Breakpoints
   ========================================================================== */

$bp-small: 480px;
$bp-medium: 768px;
$bp-large: 1025px;
$bp-xlarge: 1430px;
$bp-full: 1600px;

/* Spacing
   ========================================================================== */
$space-small: 10px;
$space: 20px;

/* Sizing
   ========================================================================== */
$header-height: 91px;

/* Colors
   ========================================================================== */

// Colors shouldn't be used directly but reassigned
$color-very-light-grey: #E5E5E5;
$color-light-grey: #D1D1D1;
$color-grey: #aaaaaa;
$color-dark: #161616;
$color-smoke: #F3F1E9;
$color-brown-very-light: #EFECE1;
$color-brown-light: #C8AA81;
$color-brown: #B3946A;
$color-green: #4b5649;
$color-sustainable: #3F6242;
$color-organic: #9D5B47;

// Text
$color-bg: white;
$color-text: #45443D;

// Links
$color-hover: $color-light-grey;
