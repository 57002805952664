.home {
  .c-header:after {
    opacity: 0;
    transition: $transition;
  }

  .c-main-nav--wrapper {
    background-color: transparent;

    &.is-active {
      background-color: white;
    }
  }

  .c-main-nav__dropdown--wrapper {
    opacity: 0;
    transform: translateY(0);

    &.is-active,
    &.mobile {
      opacity: 1;
    }
  }

  .c-main-nav__link.has-children {
    background: url('../assets/images/icons/arrow-down-white.svg') no-repeat right center;
  }

  .main-content--wrapper {
    padding-top: 0;
  }

  .c-header--logo {
    position: relative;
  }

  .c-header--logo-white,
  .c-header--logo-normal {
    transition: $transition;
  }

  .c-header--logo-normal {
    opacity: 0;
  }

  .c-header--logo-white {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    z-index: 1;

    @include bp-medium {
      left: $space-small;
    }
  }

  .c-main-nav__link {
    color: white;
    transition: $transition;

    &:hover {
      color: white;
    }
  }

  .is-active {
    .c-main-nav__link {
      color: black;
      transition: $transition;

      &:hover {
        color: black;
      }
    }
  }

  .hamburger.is-active .hamburger-inner,
  .hamburger.is-active .hamburger-inner::before,
  .hamburger.is-active .hamburger-inner::after,
  .hamburger-inner,
  .hamburger-inner::before,
  .hamburger-inner::after {
    background-color: white;
    transition: $transition;
  }

  &.scrolled {
    .c-header:after {
      opacity: 1;
    }

    .c-header--logo-normal {
      opacity: 1;
    }

    .c-header--logo-white {
      opacity: 0;
    }

    .c-header--logo-normal {
      opacity: 1;
    }

    .c-main-nav__link {
      color: $color-text;

      &:hover {
        color: $color-text;
      }
    }

    .c-main-nav__link.has-children {
      background: url('../assets/images/icons/arrow-down.svg') no-repeat right center;
    }

    .hamburger.is-active .hamburger-inner,
    .hamburger.is-active .hamburger-inner::before,
    .hamburger.is-active .hamburger-inner::after,
    .hamburger-inner,
    .hamburger-inner::before,
    .hamburger-inner::after {
      background-color: black;
    }
  }

  .c-hero {
    background-position: left bottom;

    @include bp-medium {
      height: 100vh;
      min-height: 100vh;
      background-position: center bottom;
    }

    h1 {
      margin-bottom: $space;
      font-weight: $font-semibold;
    }
  }

  .c-hero--content {
    @include bp-medium {
      top: 50%;
      padding-top: 0;
      transform: translateY(-50%);
    }
  }

  .c-hero--text {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    color: white;

    @include bp-medium {
      max-width: 620px;
    }
  }

  .image-title {
    background: url('../assets/images/bg-grown-milled-min.jpg') no-repeat center center;
    background-size: cover;
  }
}