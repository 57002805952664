.pagination {
  display: flex;
  justify-content: space-between;
  margin-top: $space;

  @include bp-medium {
    justify-content: flex-end;
    margin-top: $space * 2;
    padding: 0 $space;
  }

  @include bp-large {
    margin-top: $space * 3;
  }

  @include bp-xlarge {
    margin-top: $space * 5;
  }

  &-btn {
    @extend .c-btn;

    @include bp-large {
      padding: 12px $space * 1.5;
      font-size: 21px;
    }
  }

  &-next {
    margin-left: $space;
  }
}
